'use client';

import { Button } from '@ahafood/ui/components/button';
import { Input } from '@ahafood/ui/components/input';
import { cn } from '@ahm/common-helpers';
import { ChevronDown, X } from 'lucide-react';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import HeaderSearch from './header-search';
import StoreCityIdDialog from '@/app/[locale]/(main)/store/components/store-city-id-dialog';
import { ROUTES } from '@/config/router';
import { usePathname } from '@/i18n/routing';
import { CITIES, useAiFoodOrderStore } from '@/store';

export default function HeaderFilter() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [isOpenSearchDialog, setIsOpenSearchDialog] = useState<boolean>(false);
  const lat = searchParams.get('lat')
    ? parseFloat(searchParams.get('lat')!)
    : undefined;
  const lng = searchParams.get('lng')
    ? parseFloat(searchParams.get('lng')!)
    : undefined;

  const cityId = useAiFoodOrderStore.use.city_id();
  const address = useAiFoodOrderStore.use.address();
  const setAddress = useAiFoodOrderStore.use.setAddress();

  const selectedCity = useMemo(() => {
    return CITIES.find((c) => c.value === cityId);
  }, [cityId]);

  const addressSelected = useMemo(() => {
    if (cityId && !address) {
      return selectedCity?.label;
    }
    if (address && cityId) {
      return address.address;
    } else {
      return 'Nhập vị trí của bạn';
    }
  }, [address, cityId, selectedCity]);

  useEffect(() => {
    if (!cityId && !!lat && !!lng) {
      setIsOpenSearchDialog(false);
    } else {
      setIsOpenSearchDialog(!cityId ? true : false);
    }
  }, [cityId, lat, lng]);

  return (
    <>
      <StoreCityIdDialog
        showCityPopup={isOpenSearchDialog}
        setShowCityPopup={setIsOpenSearchDialog}
        setAddress={setAddress}
        address={address}
      />
      <div className="flex w-full flex-col justify-end gap-3 md:flex-row md:gap-4">
        <div
          className={cn('relative w-full md:max-w-80', {
            // hidden: pathname === ROUTES.search,
          })}
        >
          {address ? (
            <Button
              variant="ghost"
              size="sm"
              className="absolute right-2 top-[14px] size-5 bg-white p-0 hover:bg-neutral-20 md:top-5"
              aria-label="Clear input"
              onClick={() => {
                setAddress(null);
              }}
            >
              <X className="size-5" />
            </Button>
          ) : null}
          <label className="absolute left-[18px] top-2 flex flex-none items-center text-xs font-medium text-neutral-70 md:top-3">
            Vị trí nhận món
            <ChevronDown className="ml-1" color="orange" size={16} />
          </label>
          <Input
            className="h-12 w-full justify-start truncate rounded-xl border-neutral-20 bg-neutral-10/30 pl-4 pr-10 text-sm font-bold hover:bg-neutral-30/30 md:h-16 md:max-w-96"
            value={addressSelected}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={() => {}}
            aria-haspopup="listbox"
            aria-expanded={isOpenSearchDialog}
            style={{
              paddingTop: 25,
            }}
            onClick={() => setIsOpenSearchDialog(true)}
          />
        </div>
        {pathname === ROUTES.search ? null : (
          <HeaderSearch inputSearchClassName="h-12" />
        )}
      </div>
    </>
  );
}
