'use client';

import { Button } from '@ahafood/ui/components/button';
import { Separator } from '@ahafood/ui/components/separator';
import { AhafoodLogo } from '@ahamove/icons/order';
import { JSFunction } from '@ahamove/js-function';
import { cn } from '@ahm/common-helpers';
import { ArrowLeft, X } from 'lucide-react';
import classes from './main-layout.module.css';
import HeaderFilter from '@/components/store/header-filter';
import { publicEnv } from '@/config/public-env.config.mjs';
import { ROUTES } from '@/config/router';
import { Link, usePathname } from '@/i18n/routing';
import { useIsMobile } from '@/lib/is-mobile';
import { useDetectWebInApp } from '@/lib/is-webinapp/use-detect-webinapp';

export default function MainHeader() {
  const pathname = usePathname();
  const isMobile = useIsMobile();
  const isWebinapp = useDetectWebInApp();
  if (pathname === ROUTES.search && isMobile) {
    return null;
  }

  if (pathname.includes(ROUTES.store + '/')) {
    return (
      <header className={cn(classes.header, 'bg-white')}>
        <div className="mx-auto flex flex-row w-full md:h-20 h-16 max-w-screen-lg items-center px-4">
          <Link href={ROUTES.store} passHref>
            <Button
              variant="link"
              size="lg"
              className="-ml-4 px-4 text-xl font-bold text-neutral-60 hover:text-neutral-90 md:h-16 md:space-x-2 [&_svg]:size-6"
            >
              <ArrowLeft />
              <span className="hidden md:inline-block">Quay lại</span>
            </Button>
          </Link>
          <AhafoodLogo className="h-10 w-full md:mr-[150px] mr-11" />
        </div>
      </header>
    );
  }

  return (
    <>
      {Boolean(isWebinapp) && pathname !== ROUTES.search ? (
        <div className="flex h-16 w-full flex-row items-center justify-center gap-2 px-4 py-3 border-b sticky top-0 inset-x-0 z-50 bg-white">
          <Button
            variant="ghost"
            className="absolute left-1.5 top-1/2 -translate-y-1/2 size-10 flex-none p-2 [&_svg]:size-6"
            onClick={() => {
              JSFunction.call({ name: 'close' });
            }}
          >
            <X />
          </Button>
          <Link
            className="flex flex-none items-center justify-start"
            href={isWebinapp ? '' : ROUTES.home}
          >
            <AhafoodLogo className="h-10 w-36" />
            <h1 className="sr-only">{publicEnv.NEXT_PUBLIC_APP_TITLE}</h1>
          </Link>
        </div>
      ) : null}
      <header
        className={cn(classes.header, {
          // 'pt-12': isWebinapp && pathname !== ROUTES.search,
          // fixed: pathname !== ROUTES.store,
          // 'static md:fixed': pathname === ROUTES.store,
          'bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60':
            pathname !== ROUTES.search,
          'bg-background': pathname === ROUTES.search,
          '!static': isWebinapp,
        })}
      >
        <div
          className={cn(
            'container mx-auto flex h-auto w-full max-w-screen-xl flex-col items-center justify-between gap-2 sm:flex-row md:h-20',
            {
              'px-4 py-3 md:gap-4 md:px-6': !isWebinapp,
              'p-0': isWebinapp,
            }
          )}
        >
          {!isWebinapp ? (
            <div
              className={cn(
                'flex w-full flex-1 flex-row flex-nowrap items-center',
                {
                  // hidden: isWebinapp,
                }
              )}
            >
              <Link
                className="mr-4 flex flex-none items-center justify-start md:mr-6"
                href={ROUTES.home}
              >
                <AhafoodLogo className="h-11 w-[160px] sm:h-14 sm:w-[259px] -mt-0.5" />
                <h1 className="sr-only">{publicEnv.NEXT_PUBLIC_APP_TITLE}</h1>
              </Link>
              <Separator orientation="vertical" className="h-5" />
              <Link passHref href={ROUTES.store}>
                <Button
                  variant="link"
                  className={cn(
                    'h-10 w-full rounded bg-transparent px-4 py-0 text-lg font-bold !no-underline hover:bg-transparent md:h-14 md:px-6 md:text-xl',
                    {
                      'text-neutral-60 hover:text-neutral-90':
                        pathname !== ROUTES.store,
                      'text-primary-50': pathname === ROUTES.store,
                    }
                  )}
                >
                  Đặt món ăn
                </Button>
              </Link>
            </div>
          ) : null}

          {![ROUTES.home].includes(pathname) &&
          !pathname.includes(ROUTES.store + '/') ? (
            <div
              className={cn('w-full', {
                'px-4 py-3': isWebinapp,
              })}
            >
              <HeaderFilter />
            </div>
          ) : null}
        </div>
      </header>
    </>
  );
}
