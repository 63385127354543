'use client';
import { Separator } from '@ahafood/ui/components/separator';
import {
  AhafoodLogo,
  FacebookIcon,
  LinkedinIcon,
  TikTokIcon,
  YoutubeIcon,
} from '@ahamove/icons/order';
import Link from 'next/link';
import ContactFooter from './contact-footer';
import { ROUTES } from '@/config/router';
import { usePathname } from '@/i18n/routing';
import { useDetectWebInApp } from '@/lib/is-webinapp/use-detect-webinapp';

export default function MainFooter() {
  const pathname = usePathname();
  const isWebinapp = useDetectWebInApp();

  if (Boolean(isWebinapp) || pathname.includes(ROUTES.store + '/')) {
    return null;
  }

  return (
    <footer className="border-t border-neutral-15 bg-white">
      <div className="mx-auto max-w-screen-xl space-y-4 px-6 py-8">
        <div className="block md:flex md:justify-between">
          <ContactFooter />
          <AhafoodLogo
            width={248}
            height={61}
            className="hidden flex-none md:block"
          />
        </div>
        <Separator className="md:hidden" />
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="col-span-1 flex flex-col space-y-3 md:flex-row md:items-center md:space-x-6 md:space-y-0">
            <p className="text-sm font-medium text-black md:text-base">
              Kết nối với chúng tôi
            </p>
            {/* <SocialProfileJsonLd
              type="Organization"
              name={process.env.NEXT_PUBLIC_APP_NAME!}
              url={baseUrl ?? ''}
              sameAs={[
                'https://www.linkedin.com/company/ahamove/',
                'https://www.facebook.com/AhamoveVietNam',
                'https://www.youtube.com/@ahamoveofficial6703',
                'https://www.tiktok.com/@ahamove.official',
              ]}
            /> */}
            <div className="flex flex-row items-center justify-start space-x-3 md:justify-center">
              <Link
                href="https://www.linkedin.com/company/ahamove/"
                target="_blank"
                className="relative inline-block size-6 bg-transparent"
                title="LinkedIn"
              >
                <LinkedinIcon className="size-full" />
              </Link>
              <Link
                href="https://www.facebook.com/ahafoodai"
                target="_blank"
                className="relative inline-block size-6 bg-transparent"
                title="Facebook"
              >
                <FacebookIcon className="size-full" />
              </Link>
              <Link
                href="https://www.youtube.com/channel/UCQ38OzCARV2qCZxmK2XiKJA"
                target="_blank"
                className="relative inline-block size-6 bg-transparent"
                title="Youtube"
              >
                <YoutubeIcon className="size-full" />
              </Link>
              <Link
                href="https://www.tiktok.com/@ahamove.official?_d=secCgYIASAHKAESPgo8JCPk1tTNqUv%2FcrgqEKxfidesktopWylVPUXqnp8PNXevHbDGxYaYjz9M9R4NqEQ00ZXkigL5aYlu4FEawCqmNGgA%3D&_r=1&checksum=53d324c5fe3b252911eed1c58653df332e7d7c881ce9b3a18f3b9cd8e972a935&language=vi&sec_uid=MS4wLjABAAAAqHDL7Gyk9GkYZju5XNRAcT0ozhhtL0izo4G9-GpvQlu-tAb4Le6aLZkA8iNLAN5t&sec_user_id=MS4wLjABAAAAwB8nLxIEUUH1oeqceKYFJD1JEWtxmntl5inS2eUpisTrUuV4VgP7cU_8xwmFHarN&share_app_id=1180&share_author_id=7017802434521695259&share_link_id=76A33192-8832-40EE-935F-BBE2E617F834&source=h5_t&tt_from=copy&u_code=dada33bbm0ick8&user_id=6784425419073537026&utm_campaign=client_share&utm_medium=ios&utm_source=copy"
                target="_blank"
                className="relative inline-block size-6 bg-transparent"
                title="Tiktok"
              >
                <TikTokIcon className="size-full" />
              </Link>
            </div>
          </div>
          {/* <div className="col-span-1 flex flex-row justify-start space-x-6 md:mt-0 md:justify-end md:space-x-4">
            <Link href="http://online.gov.vn/Home/WebDetails/49572" passHref>
              <CertRegistered className="relative inline-block h-14 w-36 bg-transparent" />
            </Link>
            <Link href="http://online.gov.vn/Home/AppDetails/168" passHref>
              <CertNotificated className="relative inline-block h-14 w-36 bg-transparent" />
            </Link>
          </div> */}
        </div>
        <Separator className="md:!my-6" />
        <div className="space-y-1 text-xs font-semibold text-neutral-60">
          <p>
            Giấy chứng nhận đăng ký doanh nghiệp số 0313506115 cấp lần đầu ngày
            26/10/2015 tại Sở Kế hoạch đầu tư Thành phố Hồ Chí Minh, cấp thay
            đổi lần thứ 09 ngày 15/03/2023.
          </p>
          <p>
            Văn bản xác nhận thông báo hoạt động Bưu chính số 2418/XN-BTTTT do
            Bộ Thông tin và Truyền thông cấp lần đầu ngày 11/04/2017, cấp điều
            chỉnh lần thứ 1 ngày 24/7/2019.
          </p>
          <p className="!mt-2">
            Địa chỉ ĐKKD: 405/15 Xô Viết Nghệ Tĩnh, Phường 24, Quận Bình Thạnh,
            Thành phố Hồ Chí Minh, Việt Nam
          </p>
        </div>
      </div>
    </footer>
  );
}
